:root {
  --clr-accent-100: #f2f2f2;
  --clr-accent-500: #b3b3e6;
  --clr-accent-700: #6f6ffc;
  --clr-neutral-100: #fff;
  --clr-neutral-400: #999;
  --clr-neutral-500: #777;
  --clr-neutral-700: #333;
  --clr-neutral-900: #000;
  --ff-primary: "Trispace", sans-serif;
  --ff-body: var(--ff-primary);
  --ff-heading: var(--ff-primary);
  --fw-small: 500;
  --fw-regular: 600;
  --fw-semi-bold: 700;
  --fw-bold: bold;
  --fs-100: .75rem;
  --fs-200: .8125rem;
  --fs-300: .875rem;
  --fs-400: 1rem;
  --fs-500: 1.25rem;
  --fs-600: 1.5rem;
  --fs-700: 2rem;
  --fs-800: 2.5rem;
  --fs-900: 3.5rem;
  --fs-body: var(--fs-200);
  --fs-primary-heading: var(--fs-700);
  --fs-secondary-heading: var(--fs-600);
  --fs-tertiary-heading: var(--fs-500);
  --fs-nav: var(--fs-200);
  --fs-attribution: var(--fs-100);
  --size-100: .25rem;
  --size-200: .5rem;
  --size-300: .75rem;
  --size-400: 1rem;
  --size-500: 1.5rem;
  --size-600: 2rem;
  --size-700: 3rem;
  --size-800: 4rem;
  --size-900: 10rem;
  --scale: 0;
  --arrowSize: 10px;
}

@media (width >= 50em) {
  :root {
    --fs-body: var(--fs-400);
    --fs-nav: var(--fs-400);
  }
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

html, body {
  height: 100%;
}

body {
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture, svg {
  max-width: 100%;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

body {
  font-size: var(--fs-body);
  font-family: var(--ff-body);
  color: var(--clr-neutral-900);
}

.site-container {
  overflow-x: hidden;
}

header {
  background-color: var(--clr-neutral-900);
  color: var(--clr-neutral-100);
  height: 100vh;
}

@keyframes header-title {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.header-title {
  text-align: center;
  font-size: clamp(2.5rem, 9vw, 8rem);
  font-weight: var(--fw-regular);
  animation-name: header-title;
  animation-duration: 2s;
}

.header-title .last-name {
  color: #6f6ffc;
}

@keyframes navigation {
  from {
    opacity: 0;
    transform: translateY(200%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.navigation {
  margin-top: clamp(1rem, 4vw, 3rem);
  animation-name: navigation;
  animation-duration: 1s;
}

.nav-list {
  font-size: var(--fs-nav);
  font-weight: var(--fw-small);
  justify-content: center;
  gap: clamp(1rem, 3vw, 3rem);
  display: flex;
}

.nav-list p {
  color: var(--clr-neutral-100);
}

.nav-list a {
  color: inherit;
  flex-direction: column;
  align-items: center;
  gap: .5em;
  text-decoration: none;
  display: flex;
}

.nav-icon {
  width: clamp(40px, 10vw, 110px);
}

.nav-icon.needs-shifting {
  margin-left: .4rem;
}

@keyframes float {
  0% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-5px);
  }
}

.nav-icon:hover {
  animation: 3s ease-in-out infinite float;
}

@keyframes scroll-text {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.scroll-text {
  color: #fff;
  animation-name: scroll-text;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
}

@keyframes capsule {
  from {
    top: -1000px;
  }

  to {
    top: 0;
  }
}

.scroll-down-img {
  transform-origin: center;
  width: 50px;
  animation-name: capsule;
  animation-duration: 2s;
  position: relative;
  transform: rotate(-45deg);
}

.section-header-img {
  width: 50px;
}

.section-header-img-rotate {
  transform: rotate(-45deg);
}

.back-to-top-img {
  padding-bottom: var(--size-200);
  cursor: pointer;
  width: 50px;
  transform: rotate(-45deg);
}

@keyframes rotate-float {
  0% {
    transform: translateY(-5px)rotate(-45deg);
  }

  50% {
    transform: translateY(5px)rotate(-45deg);
  }

  100% {
    transform: translateY(-5px)rotate(-45deg);
  }
}

.back-to-top-img:hover {
  animation: 3s ease-in-out infinite rotate-float;
}

.section-title:after {
  content: "";
  background-color: #6f6ffc;
  width: 0%;
  height: 2px;
  transition: all 1s .2s;
  display: block;
}

.section-title.show:after {
  width: 100%;
}

.about-photo {
  width: 400px;
  position: relative;
}

.about-content {
  padding-top: 0;
  padding-left: var(--size-200);
  padding-right: var(--size-200);
  position: relative;
}

.about-content a {
  font-weight: var(--fw-bold);
  color: var(--clr-accent-700);
}

.about-content a:hover {
  color: var(--clr-neutral-400);
}

@media (width >= 50em) {
  .about-content {
    padding: var(--size-400);
    width: 600px;
  }
}

.project-container {
  grid-template-areas: "project-image"
                       "project-description";
  justify-items: center;
  display: grid;
}

.project-img-container {
  background-color: var(--clr-accent-500);
  padding: var(--size-400);
  opacity: 0;
  grid-area: project-image;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: all 1s;
  display: flex;
  position: relative;
  left: -100px;
}

.project-img-container img {
  cursor: n-resize;
  width: 100%;
}

.project-description-container {
  background-color: var(--clr-accent-100);
  padding: var(--size-600) var(--size-400);
  opacity: 0;
  width: 100%;
  transition: all 1s;
  position: relative;
  left: 100px;
}

.project-container.show .project-img-container, .project-container.show .project-description-container {
  opacity: 1;
  left: 0;
}

.project-description-container p {
  margin-bottom: var(--size-200);
  margin-top: var(--size-200);
}

.project-description-container ul {
  padding-left: var(--size-600);
  padding-top: var(--size-200);
}

.project-description-container li {
  padding-bottom: var(--size-100);
  grid-area: project-description;
}

.project-description-container a {
  color: inherit;
  font-weight: var(--fw-bold);
}

.project-description-container a:hover {
  color: var(--clr-neutral-400);
}

.project-title {
  align-items: flex-start;
  row-gap: var(--size-100);
  flex-direction: column;
  display: flex;
}

.project-title h1 {
  font-size: var(--fs-600);
  font-weight: var(--fw-semi-bold);
}

.code-website-links {
  align-items: center;
  gap: var(--size-400);
  display: flex;
}

.project-title a {
  padding: var(--size-100);
  border: 1px solid #000;
  border: 1px solid var(--clr-accent-700);
  color: var(--clr-neutral-100);
  background-color: var(--clr-neutral-900);
  text-decoration: none;
  transition: all .2s;
}

.project-title a:hover {
  background-color: var(--clr-accent-700);
  color: var(--clr-neutral-100);
}

.project-subtitle {
  margin-top: var(--size-400);
  padding-right: var(--size-400);
  font-size: var(--fs-tertiary-heading);
  font-weight: var(--fw-small);
}

.project-description-container i {
  padding-right: var(--size-400);
}

@media (width >= 50em) {
  .project-container {
    grid-template-columns: 70vw;
  }

  .project-description-container {
    padding: var(--size-600);
  }

  .project-description-container li {
    padding-left: var(--size-400);
    padding-right: var(--size-400);
  }

  .project-title {
    flex-flow: wrap;
    justify-content: space-between;
  }
}

@media (width >= 75em) {
  .project-container {
    justify-content: center;
    width: 1200px;
    display: flex;
  }

  .project-img-container, .project-description-container {
    width: 50%;
  }
}

.contact-list i {
  font-size: var(--fs-900);
  color: var(--clr-neutral-900);
}

.contact-list i:hover {
  color: var(--clr-accent-700);
}

.copy-email {
  position: relative;
}

.copy-email:before, .copy-email:after {
  transform: translateX(-50%) translateY(-200%) scale(var(--scale));
  transform-origin: bottom;
  transition: transform .15s;
  position: absolute;
  top: -.25rem;
  left: 50%;
}

.copy-email:before {
  content: attr(data-tooltip);
  background-color: var(--clr-neutral-700);
  color: var(--clr-neutral-100);
  text-align: center;
}

.copy-email:hover:before, .copy-email:hover:after {
  --scale: 1;
}

.copy-email:after {
  content: "";
  border: var(--arrowSize) solid transparent;
  border-top-color: var(--clr-neutral-700);
  transform-origin: top;
}

@media (width >= 50em) {
  .copy-email:before {
    padding: var(--size-200);
    width: max-content;
  }
}

.contact-form {
  background-color: var(--clr-accent-100);
  margin-top: var(--size-400);
  padding: var(--size-400) var(--size-500);
  opacity: 0;
  border: 1px solid #000;
  width: 100%;
  transition: all 1s;
  position: relative;
  top: 100px;
}

.contact-form.show {
  opacity: 1;
  top: 0;
}

.contact-form img {
  padding-bottom: var(--size-400);
  width: 100px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.contact-textarea {
  resize: none;
  height: 200px;
}

.contact-form-btn {
  all: unset;
  padding: var(--size-200) var(--size-400);
  cursor: pointer;
  border: 1px solid var(--clr-accent-700);
  color: var(--clr-neutral-100);
  background-color: var(--clr-neutral-900);
  transition: all .2s;
}

.contact-form-btn:hover {
  background-color: var(--clr-accent-700);
}

@media (width >= 50em) {
  .contact-form {
    padding: var(--size-600) var(--size-800);
    width: 600px;
  }
}

footer a {
  color: var(--clr-accent-700);
}

.computer-container {
  width: 100%;
  padding-top: 65%;
  position: relative;
}

.computer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.outer-screen {
  background-color: var(--clr-neutral-900);
  border: 2px solid var(--clr-neutral-900);
  border-bottom: none;
  border-radius: 1em 1em 0 0;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80%;
  display: flex;
}

.inner-screen {
  background-color: var(--clr-neutral-100);
  border: 2px solid var(--clr-neutral-900);
  width: 93%;
  height: 80%;
  overflow-y: scroll;
}

.inner-screen-sqwordle {
  background-color: #121212;
}

.base {
  background-color: var(--clr-neutral-700);
  border: 2px solid var(--clr-neutral-900);
  border-radius: 0 0 1em 1em;
  width: 90%;
  height: 7%;
}

.container {
  padding-left: var(--size-400);
  padding-right: var(--size-400);
}

.display-flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.gap-400 {
  gap: var(--size-400);
}

.gap-600 {
  gap: var(--size-600);
}

.gap-700 {
  gap: var(--size-700);
}

.text-uppercase {
  text-transform: uppercase;
}

.fw-bold {
  font-weight: var(--fw-bold);
}

.fw-semi-bold {
  font-weight: var(--fw-semi-bold);
}

.fw-regular {
  font-weight: var(--fw-regular);
}

.fw-small {
  font-weight: var(--fw-small);
}

.fs-primary-heading {
  font-size: var(--fs-primary-heading);
  line-height: 1.1;
}

.fs-secondary-heading {
  font-size: var(--fs-secondary-heading);
  line-height: 1.1;
}

.fs-700 {
  font-size: var(--fs-700);
}

.fs-attribution {
  font-size: var(--fs-attribution);
}

.padding-300 {
  padding: var(--size-300);
}

.padding-400 {
  padding: var(--size-400);
}

.padding-top-400 {
  padding-top: var(--size-400);
}

.padding-top-800 {
  padding-top: var(--size-800);
}

.margin-bottom-200 {
  margin-bottom: var(--size-200);
}

.margin-bottom-400 {
  margin-bottom: var(--size-400);
}

.margin-bottom-500 {
  margin-bottom: var(--size-500);
}

.margin-bottom-700 {
  margin-bottom: var(--size-700);
}

.margin-top-600 {
  margin-top: var(--size-600);
}

.margin-top-900 {
  margin-top: var(--size-900);
}

.margin-top-900x3 {
  margin-top: calc(var(--size-900) * 3);
}

.visibility-hidden {
  visibility: hidden;
}

@media (width <= 50em) {
  .hidden-on-mobile {
    display: none;
  }
}
/*# sourceMappingURL=index.1ce01877.css.map */
